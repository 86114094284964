   /* index.css */
   @import '~antd/dist/antd.css';

   body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Add any custom styles here */

.site-content {
    flex: 1;
    background: #f0f2f5;
    padding: 24px 50px; /* Increased horizontal padding */
}

/* Adjust the content area for all pages */
.site-layout-content {
    background: #f0f2f5;
    padding: 24px;
    min-height: 280px;
    max-width: 1200px; /* Add a max-width to prevent content from stretching too wide on large screens */
    margin: 0 auto; /* Center the content */
    border-radius: 4px; /* Optional: adds rounded corners to the white content area */
}

/* Add responsive padding for smaller screens */
@media (max-width: 768px) {
    .site-content {
        padding: 24px 20px;
    }
}